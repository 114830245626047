import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import currentFrame from "../utils/images"
import { mobileAndDown, toRem } from '../utils/styleUtils';
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Home = ({ data }) => {

    const [maxScrollTop, setMaxScrollTop] = useState(0);
    const [doneScrolling, setDoneScrolling] = useState(false);
    const [textIndex, setTextIndex] = useState(0);
    const [win, setWindow] = useState(1000);

    const textList = [
        <TagText maxScrollTop={maxScrollTop}><span>Introducing ZipTips.</span><br /><br />An entirely new way to learn Apple's technologies.</TagText>,
        <TagText maxScrollTop={maxScrollTop}>View Tips, and walkthrough code.</TagText>,
        <TagText maxScrollTop={maxScrollTop}>Even better, download a working Swift Playground.</TagText>,
        <TagText maxScrollTop={maxScrollTop} doneScrolling={doneScrolling}>Ask questions of the iOS Developer who created the Tip.</TagText>
    ];

    useEffect(() => {
        const html = document.documentElement;
        setWindow(window.innerHeight * 80 / 100);
        maxScrollTop === 0 ? setMaxScrollTop(html.scrollHeight) : void (0);
        const frameCount = 4;
        window.addEventListener('scroll', () => {
            const scrollTop = html.scrollTop;
            scrollTop < 0 ? scrollTop = 0 : void (0);
            const scrollFraction = scrollTop / maxScrollTop;
            setDoneScrolling(scrollFraction > 1);
            if (scrollFraction <= 1) {
                const frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * (frameCount - 1)));
                setTextIndex(frameIndex);
            }
        });
    })

    return (
        <Layout navColor="#181818" background="white" toggled={false}>
            <Background maxScrollTop={maxScrollTop} vh={win}></Background>
            <Container maxScrollTop={maxScrollTop} vh={win}>
                <PhoneImage src={currentFrame[textIndex]} doneScrolling={doneScrolling} maxScrollTop={maxScrollTop} />
                <TransitionGroup>
                    <CSSTransition
                        key={textIndex}
                        timeout={1000}
                        classNames="messageout"
                    >
                        {textList[textIndex]}
                    </CSSTransition>
                </TransitionGroup>
            </Container>
            <FeaturedText>Featured Tips</FeaturedText>
            <MonthText>August 2020</MonthText>
            {/* <TipList tips={data.Tip.listTips.items.slice(0, 5)} /> */}
        </Layout>
    )
}

const FeaturedText = styled.h1`
    font-family: Circular-Bold;
    text-align: center;
    font-size: ${toRem(48)};
    color: #191919;
    margin-bottom: 0;
`

const MonthText = styled.h2`
    font-family: Circular-Book;
    text-align: center;
    font-size: ${toRem(24)};
    color: #2660F5;
    margin-top: 0;
`

const Background = styled.div`
    top: 0;
    left: 0;
    position: absolute; 
    z-index: 0;
    width: 100vw;
    height: ${props => props.maxScrollTop + 400 + props.vh}px;
    background: #131313;
`

const Container = styled.div`
    width: 100%;
    text-align: center;
    font-family: Circular-Bold;
    color: white;
    height: ${props => props.maxScrollTop + 300 + props.vh}px;
`

const PhoneImage = styled.img`
    position: ${props => props.doneScrolling ? "absolute" : "fixed"};
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 40vw;
    max-height: 80vh;
    bottom: ${props => props.doneScrolling ? -200 - props.maxScrollTop : -200}px;
    ${mobileAndDown`
        max-width: 80vw;
        max-height: 80vh;
    `}
`

const TagText = styled.h1`
    top: ${props => props.doneScrolling ? props.maxScrollTop + 80 : 80}px;
    position: ${props => props.doneScrolling ? "absolute" : "fixed"};
    left: 50%;
    transform: translate(-50%, 0%);
    width: 35vw;
    margin: 0 auto;
    padding: 5vh 15vw 0 15vw;
    font-size: 100%;
    ${mobileAndDown`
        width: 70vw;
        padding: 5vh 15vw 0 15vw;
    `}
    &.messageout-enter {
        opacity: 0.01;
        margin-top: 40px;
        transition: all 300ms; 
    }
    &.messageout-enter-active {
        margin-top: 0;
        opacity: 1;
    }
    &.messageout-exit {
        opacity: 1;
        margin-top: 0px;
        transition: all 300ms;
    }
    &.messageout-exit-active {
        margin-top: -40px;
        opacity: 0;
    }
    span {
        font-size: 125%;
    }
`

export default Home;