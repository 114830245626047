import image1 from "../assets/home-frames/frame-001.png";
import image2 from "../assets/home-frames/frame-002.png";
import image3 from "../assets/home-frames/frame-003.png";
import image4 from "../assets/home-frames/frame-004.png";

const images = [
    image1,
    image2,
    image3,
    image4
]

export default images;